import React, { FC } from "react";
import {
  Autocomplete,
  ButtonGroup,
  Heading,
  Tabs,
  Prose,
  Pill,
  Alert,
  Paragraph,
  IconV2,
  StatusIndicator,
  Disclosure,
} from "@eco/stratos-components";
import { BaseProps } from "@eco/stratos-runtime";
import Link from "next/link";

export const LandingComponents: FC<BaseProps> = ({ id, testId }) => {
  const autocompleteOptions = [
    { value: "react", label: "React" },
    { value: "typescript", label: "Typescript" },
    { value: "tailwind", label: "Tailwind" },
    { value: "design-system", label: "Design System" },
    { value: "components", label: "Components" },
    { value: "open-source", label: "Open Source" },
  ];

  return (
    <div
      id={id}
      data-test-id={testId}
      className="grid grid-cols-4 grid-rows-9 gap-3 md:grid-cols-8 md:grid-rows-8 md:bg-transparent lg:grid-cols-12 lg:grid-rows-4"
    >
      {/* Intro text */}
      <div className="col-span-4 row-span-4 mb-5 flex flex-col justify-start gap-y-5 px-2 text-center md:col-span-8 lg:col-span-4 lg:row-span-4 lg:mb-0 lg:text-left">
        <Heading mode="display" size="xxxl" as="h2">
          <span className="text-blue-900 dark:text-blue-50">
            Welcome to Eco{" "}
          </span>
        </Heading>
        <Paragraph>
          {" "}
          Prebuilt, reusable components allow teams to focus on the specialized
          coding that makes their apps unique, instead of spending time building
          another data table or button component.{" "}
        </Paragraph>
        <Paragraph>
          <b>Eco is more than just components!</b> Eco is built with UX
          standards and accessibility, as well as validation, auth, and more.{" "}
        </Paragraph>
        <div>
          <Link
            href="/getting-started/installation/"
            className="mt-2 inline-flex items-center self-center rounded-md bg-buttons-primary-bg-normal px-5
            py-4 text-sm font-medium leading-4 text-buttons-primary-text focus:outline-none  active:bg-buttons-primary-bg-active dark:bg-dark-buttons-primary-bg-normal dark:hover:bg-dark-buttons-primary-bg-hover dark:active:bg-dark-buttons-primary-bg-active"
          >
            <div className="mr-2">
              <IconV2
                type="fa"
                icon={{
                  icon: "angle-right",
                  fixedWidth: true,
                }}
              />
            </div>
            Get started with Eco
          </Link>
        </div>
      </div>

      {/* Toggle */}
      <div className="col-span-1 col-start-4 row-span-2 row-start-8 flex flex-col items-center justify-center rounded-lg bg-components-baseComponent p-2 drop-shadow dark:bg-dark-components-light md:col-start-7 md:row-start-7 lg:col-start-6 lg:row-span-1 lg:row-start-2">
        <IconV2
          type="fa"
          icon={{
            icon: ["far", "message-code"],
            fixedWidth: true,
            size: "3x",
          }}
          color="blue"
        />
      </div>

      {/* STATUS INDICATOR */}
      <div className="col-span-1 col-start-1 row-span-1 row-start-5 flex flex-col items-center justify-center rounded-lg bg-components-baseComponent p-2 drop-shadow dark:bg-dark-components-light md:col-start-2 lg:col-start-8 lg:row-start-1">
        <StatusIndicator label="100+">
          <IconV2
            type="fa"
            icon={{
              icon: ["far", "face-sunglasses"],
              fixedWidth: true,
              size: "3x",
            }}
            color="green"
          />
        </StatusIndicator>
      </div>

      {/* AUTOCOMPLETE */}
      <div className="z-50 col-span-3 col-start-2 row-span-1 row-start-5 flex flex-col items-stretch justify-center rounded-lg bg-components-baseComponent p-2 drop-shadow dark:bg-dark-components-light md:col-start-3 lg:col-span-4 lg:col-start-9 lg:row-start-1">
        <Autocomplete
          options={autocompleteOptions}
          isMulti={true}
          onChange={() => {
            return null;
          }}
        />
      </div>

      {/* DISCLOSURE */}
      <div className="col-span-2 col-start-3 row-span-2 row-start-6 flex flex-col items-stretch justify-center rounded-lg bg-components-baseComponent p-2 drop-shadow dark:bg-dark-components-light md:col-start-6 md:row-start-5 lg:col-start-11 lg:row-start-2">
        <Disclosure
          list={[
            {
              id: "greetings",
              label: "Greetings!",
              panel: (
                <Paragraph> Oh hello! I didn&apos;t see you there. </Paragraph>
              ),
            },
          ]}
        />
      </div>

      {/* BUTTONGROUP */}
      <div className="col-span-3 col-start-1 row-span-1 row-start-8 flex flex-col items-center justify-center rounded-lg bg-components-baseComponent p-2 drop-shadow dark:bg-dark-components-light md:col-span-4 md:col-start-2 md:row-start-6 lg:col-start-7 lg:row-start-2">
        <ButtonGroup>
          <ButtonGroup.Button id="one" iconName={["far", "heart"]}>
            Lets
          </ButtonGroup.Button>
          <ButtonGroup.Button id="two" iconName={["far", "robot"]}>
            Build
          </ButtonGroup.Button>
          <ButtonGroup.Button id="three">Some</ButtonGroup.Button>
          <ButtonGroup.Button id="four">Apps</ButtonGroup.Button>
        </ButtonGroup>
      </div>

      {/* TABS */}
      <div className="z-40 col-span-2 col-start-1 row-span-2 row-start-6 flex flex-col justify-center rounded-lg bg-components-baseComponent p-2 drop-shadow  dark:bg-dark-components-light md:col-start-2 md:row-start-7 lg:col-start-9 lg:row-start-3">
        <Tabs
          tabs={[
            {
              label: "To the Moon!",
              panel: (
                <Prose>
                  <p>3, 2, 1 Blast Off!</p>
                </Prose>
              ),

              iconName: ["far", "rocket"],
              hideLabel: true,
              id: "tab-02",
            },
            {
              label: "Space Cat",
              panel: (
                <Prose>
                  <p>Cleo the Space Cat</p>
                </Prose>
              ),
              iconName: ["far", "cat-space"],
              hideLabel: true,
              id: "tab-03",
            },
          ]}
        />
      </div>

      {/* PILLS */}
      <div className="col-span-2 col-start-1 row-span-1 row-start-9 flex flex-row flex-wrap items-center justify-center gap-3 rounded-lg bg-components-baseComponent p-2 drop-shadow dark:bg-dark-components-light md:col-span-3 md:col-start-4 md:row-start-8 lg:col-start-6 lg:row-start-3">
        <Pill
          message="Hi There"
          dismissible={true}
          variant="primary"
          onDismiss={() => {
            return null;
          }}
        />
        <Pill
          message="Howdy"
          dismissible={true}
          variant="positive"
          onDismiss={() => {
            return null;
          }}
        />
        {/* <Pill
          message="Buenos Dias"
          dismissible={true}
          variant="primary"
          onDismiss={() => {
            return null;
          }}
        /> */}
      </div>

      {/* ALERT */}
      <div className="col-span-2 col-start-3 row-span-1 row-start-9 flex flex-col  items-stretch justify-center rounded-lg bg-components-baseComponent p-2 drop-shadow dark:bg-dark-components-light md:col-span-3 md:col-start-4 md:row-start-7 lg:col-span-4 lg:col-start-5 lg:row-start-4">
        <Alert
          variant="success"
          heading="Success!"
          dismissible
          onDismiss={() => null}
          message="Welcome to Eco!"
        />
      </div>
    </div>
  );
};

export default LandingComponents;
