import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Icon } from "@eco/stratos-components";

const TilBadge = () => {
  const { ecoComponentsShowTilBadge060823 } = useFlags();
  return (
    <>
      {ecoComponentsShowTilBadge060823 && (
        <div
          className="absolute right-1 top-[3.5rem] z-til h-28 w-28 origin-center animate-spinTil opacity-0"
          // eslint-disable-next-line no-console
          onClick={() => console.log("til badge clicked!")}
          role="presentation"
        >
          <span className="fa-layers-text pointer-events-none relative left-4 top-[4rem] z-til text-3xl font-black text-red-500">
            TIL !
          </span>
          <Icon
            icon="fa-solid fa-certificate"
            color="amber"
            className="h-24 w-24 cursor-pointer text-amber-300 drop-shadow-[0px_0px_4px_rgba(0,0,0,0.50)]"
          />
        </div>
      )}
    </>
  );
};

export default TilBadge;
