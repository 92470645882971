import { FC, ReactElement, useState } from "react";
import { MobileMenu } from "../components/navbar/MobileMenu";
import { defaultNav } from "../navigation/default-nav";
import { NavigationItem } from "../components/vertical-navigation/utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import NavBar from "../components/navbar/NavBar";
import { Footer } from "@eco/stratos-components";

export interface LayoutProps {
  children: ReactElement;
  nav?: NavigationItem[];
}

const Layout_FullWidth_Splash: FC<LayoutProps> = ({
  children,
  nav = defaultNav,
}) => {
  // Feature flag to set showing either the Build with Eco Nav or the original nav
  const { ecoComponentsShowBuildWithEco011923 } = useFlags();
  let navigation = ecoComponentsShowBuildWithEco011923 ? nav : defaultNav;

  const [showMenu, setShowMenu] = useState<boolean>(false);

  return (
    <>
      <div className="fixed z-[100] w-full">
        <NavBar showMenu={setShowMenu} />
        <MobileMenu
          sectionNav={navigation}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
        />
      </div>
      <div>
        <div className=" flex min-h-screen flex-row">
          <div className="grow">{children}</div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Layout_FullWidth_Splash;
