import type { ReactElement } from "react";
import Layout_FullWidth_Splash from "../layouts/Layout_FullWidth_Splash";
import {
  IconV2,
  Heading,
  Paragraph,
  Button,
  Prose,
  Card,
  Footer,
} from "@eco/stratos-components";
import GitlabLogo from "../components/GitlabLogo";
import SlackLogo from "../components/SlackLogo";
import LandingComponents from "../components/LandingComponents";
import Link from "next/link";
import TilBadge from "../components/TilBadge";
import { IconName } from "@fortawesome/fontawesome-common-types";

const products = [
  {
    name: "Build with Eco",
    description: "UI components, widgets, and recipes",
    icon: "code",
    link: "https://eco.dtn.com/build-with-eco/user-interface/",
  },
  {
    name: "Auth",
    description:
      "Dedicated Eco Kubernetes resources to establish auth in your app",
    icon: "key",
    link: "https://eco.dtn.com/build-with-eco/global-services/#identity-section",
  },
  {
    name: "Analytics",
    description: "Analytics and session replay options available",
    icon: "chart-line",
    link: "https://eco.dtn.com/build-with-eco/global-services/#Analytics",
  },
];

const quickLinks = [
  {
    text: "Get Started",
    href: "/getting-started/installation/",
    content: "Download the Eco Starter Kit today!",
  },

  {
    text: "Contribute",
    href: "/getting-started/oss-contribution/",
    content: "Eco is an open-source platform",
  },
];

const contacts = [
  {
    name: "Slack",
    text: "Slack us at #eco-components-public",
    icon: <SlackLogo />,
    href: "https://dtnse1.slack.com/archives/C01JR2UADQ8",
  },
  {
    name: "Gitlab",
    text: "Check out our community sandbox",
    icon: <GitlabLogo />,
    href: "https://gitlab.com/dtnse/kubernetes/eco/dtn-eco-design-system",
  },
];

const Home = () => (
  <main className="mt-16">
    {/* Intro */}

    <TilBadge />
    <section className=" bg-gradient-to-tr from-green-200 via-blue-100 to-blue-300 px-4 py-16 dark:from-green-900 dark:via-blue-950 dark:to-blue-900 sm:px-6 lg:px-8">
      <div className="mx-auto flex max-w-screen-2xl flex-row gap-5 px-0 lg:px-8 ">
        <LandingComponents />
      </div>

      <div className="mx-auto mt-16 hidden max-w-screen-2xl sm:block">
        <ul className="font-sm mx-auto flex w-full flex-row justify-around text-sm font-semibold text-primaryText dark:text-dark-primaryText md:w-5/6">
          <li className="flex flex-col items-center gap-3 pt-2">
            <span className="text-link dark:text-dark-link">
              <IconV2
                type="fa"
                icon={{ icon: ["far", "code"], fixedWidth: true, size: "2x" }}
              />
            </span>
            Open Source
          </li>
          <li className="flex flex-col items-center gap-3 pt-2">
            <span className="text-link dark:text-dark-link">
              <IconV2
                type="fa"
                icon={{
                  icon: ["far", "handshake"],
                  fixedWidth: true,
                  size: "2x",
                }}
              />
            </span>
            Solid UX
          </li>

          <li className="flex flex-col items-center gap-3 pt-2">
            <span className="text-link dark:text-dark-link">
              <IconV2
                type="fa"
                icon={{ icon: ["far", "atom"], fixedWidth: true, size: "2x" }}
                color="blue"
              />
            </span>
            React
          </li>
          <li className="flex flex-col items-center gap-3 pt-2">
            <span className="text-link dark:text-dark-link">
              <IconV2
                type="fa"
                icon={{ icon: ["far", "tree"], fixedWidth: true, size: "2x" }}
                color="blue"
              />
            </span>
            Consistent
          </li>
          <li className="flex flex-col items-center gap-3 pt-2">
            <span className="text-link dark:text-dark-link">
              <IconV2
                type="fa"
                icon={{
                  icon: ["far", "toolbox"],
                  fixedWidth: true,
                  size: "2x",
                }}
                color="blue"
              />
            </span>
            Quick to Build
          </li>
        </ul>
      </div>
    </section>
    {/* Blurb and Stats */}
    <section className="bg-blue-700 py-10 dark:bg-blue-900  md:py-16 lg:px-8">
      <div className="mx-auto max-w-screen-2xl gap-5 px-4 sm:px-6 md:grid md:grid-cols-2">
        <div className="mb-5 md:mb-0">
          <p className="text-sm leading-[1.7142857] text-blue-100 md:leading-7">
            The Eco Docs site is home to all of the information that you need to
            get started using Eco components. We are constantly adding new
            information and making improvements. Check back often to see
            what&apos;s available!
          </p>
        </div>
        <div className="grid grid-cols-2 gap-5">
          {quickLinks.map((link) => (
            <div
              key={link.text}
              className="border-l-2 border-blue-500 pl-3 dark:border-blue-400"
            >
              <h2 className="text-2xl font-extrabold text-blue-300 md:text-3xl">
                <Link
                  href={link.href}
                  className="text-blue-200 hover:text-blue-100"
                >
                  {link.text}
                </Link>
              </h2>
              <p className="text-sm text-blue-100">{link.content}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
    {/*  Products */}
    <section className="w-full bg-container-medium py-16 dark:bg-dark-container-medium ">
      <div className="mx-auto max-w-screen-2xl flex-row gap-5 px-6 sm:flex sm:px-0 lg:px-8">
        <div className="mb-3 sm:mb-0 sm:w-1/2">
          <div className="mb-4 mt-1">
            <Heading as="h2" size="xxl" mode="display">
              What&apos;s included with Eco?{" "}
            </Heading>
            <br />
          </div>

          <div className="grid grid-cols-1 flex-col gap-3 sm:flex">
            {products.map((product) => (
              <div
                key={product.name}
                className="flex flex-row space-x-3 rounded-lg border border-light bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-text-link dark:border-dark-light dark:bg-dark-container-baseContainer dark:focus-within:ring-dark-text-link"
              >
                <div className="h-12 rounded-md bg-blue-100 p-2 text-blue-700 dark:bg-blue-800 dark:text-blue-300">
                  <IconV2
                    type="fa"
                    icon={{
                      icon: ["far", product.icon as IconName],
                      fixedWidth: true,
                      size: "2x",
                    }}
                  />
                </div>
                <div className="flex flex-col space-y-2">
                  <h3 className="text-sm font-semibold text-primaryText dark:text-dark-primaryText">
                    {product.name}
                  </h3>
                  <p className="text-sm text-secondary dark:text-dark-secondary">
                    {product.description}
                  </p>
                  {product.link !== "#tbd" && (
                    <Link
                      href={product.link}
                      className="flex cursor-pointer flex-row gap-x-1 text-xs font-semibold text-link hover:text-link-hover dark:text-dark-link hover:dark:text-dark-link-hover"
                    >
                      Learn More{" "}
                      <IconV2
                        type="fa"
                        icon={{
                          icon: ["far", "angle-right"],
                          fixedWidth: true,
                          size: "xs",
                        }}
                      />
                    </Link>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Code Example */}
        <div className="h-fill hidden flex-col justify-center rounded  bg-components-lighter dark:bg-dark-components-lighter sm:flex sm:w-1/2">
          <div className="p-4">
            <Card>
              <div className="flex items-center justify-between">
                <Heading size="sm">Hello, Eco!</Heading>
                <Button iconName={["far", "gear"]} hideLabel variant="tertiary">
                  Preferences
                </Button>
              </div>
              <div className="border-y">
                <Prose>
                  <p>Check out the Eco components in action!</p>
                  <p>
                    The Card component is holding it all together, Prose is
                    keepin&apos; it pretty, there&apos;s a helpful Header, and
                    even a Button!
                  </p>
                  <p>
                    But wait, there&apos;s more! Check out the{" "}
                    <Link href="https://eco.dtn.com/build-with-eco/user-interface/">
                      Docs
                    </Link>{" "}
                    section for more.
                  </p>
                </Prose>
              </div>
              <div className="mt-4 flex justify-end">
                <Button variant="primary">Let&apos;s Do This!</Button>
              </div>
            </Card>
          </div>
          <div className="relative top-2"></div>
        </div>
        {/* End Code Example */}
      </div>
    </section>
    {/* Contact */}
    <section className="mx-auto max-w-screen-2xl px-4  py-10 sm:px-6 md:py-16 lg:px-8">
      <div className="mb-4 md:w-1/2">
        <Heading as="h3" size="sm">
          Contact Eco
        </Heading>
        <div className="mb-4 mt-1">
          <Heading as="h2" size="xxl" mode="display">
            We would love to chat!
          </Heading>
        </div>
        <Paragraph>
          We have lots of helpful information on our Eco Docs site. But, when
          the doc site just won&apos;t cut it, here are some ways to reach the
          Eco team.
        </Paragraph>
      </div>

      <div className="flex flex-col gap-5 md:flex-row">
        {contacts.map((contact) => (
          <div
            key={contact.name}
            className="flex flex-1 flex-row gap-5 rounded-lg border border-dark bg-components-baseComponent px-6 py-5 shadow-sm dark:border-dark-dark dark:bg-dark-components-baseComponent"
          >
            <span className="h-12 w-12 fill-blue-500">{contact.icon}</span>
            <div>
              <div className="flex flex-row">
                <Heading as="h2" size="sm">
                  {contact.name}
                </Heading>
                <a href={contact.href}>
                  <IconV2
                    type="fa"
                    icon={{
                      icon: ["far", "arrow-up-right-from-square"],
                      fixedWidth: true,
                      size: "xs",
                    }}
                    color="blue"
                  />
                </a>
              </div>
              <Paragraph>{contact.text}</Paragraph>
            </div>
          </div>
        ))}
      </div>
    </section>
  </main>
);

Home.layout = "fullWidth";

Home.getLayout = (page: ReactElement) => {
  return <Layout_FullWidth_Splash>{page}</Layout_FullWidth_Splash>;
};

export default Home;
